import React, { useState } from 'react';
import './App.css';
import data from './content/data.json';
import conzet_logo from './conzet-logo.svg';
import mb_logo from './content/mbg-logo.png';
import iff_logo from './content/iff_logo.png';
import { makeStyles, MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import SwapHorizSharpIcon from '@material-ui/icons/SwapHorizSharp';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ReactCardFlip from 'react-card-flip';
import IconButton from '@material-ui/core/IconButton';
import { CardMedia } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faXingSquare } from '@fortawesome/free-brands-svg-icons';

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 320,
    maxWidth: 320,
    height: 580
  },
  header: {
    backgroundColor: '#fff',
    textAlign: 'center',
    flexDirection: 'column',
    alignItems: 'baseline',
    paddingBottom: '10px',
  },
  headeraction: {
    position: 'fixed',
    right: 5,
    top: 12,
  },
  avatar: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  content: {
    padding: 0,
    display: 'inline-block',
  },
  button: {
    color: '#fff',
    backgroundColor: 'rgba(66,75,209,.9)',
  },
  button_mbg: {
    color: '#fff',
    backgroundColor: '#D50032',
  },
  button_iff: {
    color: '#fff',
    backgroundColor: '#c80f38',
  },
  avatarwrapper: { justifyContent: "center", display: "flex", paddingBottom: '16px' },
  media: {
    height: 170,
    backgroundSize: 'contain',
  },
  CardActions: {
    justifyContent: 'center',
    height: 37,
  },
  switch: { color: '#282943', fontSize: 24 },
  switch_mbg: { color: '#D50032', fontSize: 24 },
  switch_iff: { color: '#c80f38', fontSize: 24 },
  ListItem: { paddingBottom: 3, paddingTop: 3 },
  socialIcon: {
    minWidth: 0,
    paddingLeft: 3,
    paddingRight: 3,
  },
  responsive: {
    top: '50%',
    position: 'fixed',
    transform: 'translateY(-50%)',
  },
  vcenter: {
    display: 'flex',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'row',
  },
  avcard: {
    backgroundColor: '#f9f9f9',
    borderRadius: '18px',
    display: 'inline-block',
    paddingBottom: '25px',
    width: '60%'
  },
  logo: {
    height: '19px',
  }


}));

const manifest = {
  "short_name": "conzet",
  "name": "conzet Visitenkarte",
  "icons": [
    {
      "src": "./fav.png",
      "sizes": "64x64 32x32 24x24 16x16",
      "type": "image/x-icon"
    },
    {
      "src": "logo.png",
      "type": "image/png",
      "sizes": "192x192"
    },
    {
      "src": "logo.png",
      "type": "image/png",
      "sizes": "512x512"
    }
  ],
  "start_url": ".",
  "display": "standalone",
  "theme_color": "#000000",
  "background_color": "#ffffff",
  "orientation": "portrait",
  "lang": "de-DE"
}

const THEME = createTheme({
  palette: {
    text: {
      primary: "#282943",
      secondary: "#282943"
    }
  },
  typography: {
    "fontFamily": `Satoshi,sans-serif;`,
    "color": "#282943"
  }
});

function App() {
  const classes = useStyles();
  let cardData = null;
  let [cardData64, setcardData64] = useState(null);
  let [flipped, setFlipped] = useState(false);
  var logo = conzet_logo;
  var btnclass = classes.button;
  var switchclass = classes.switch;

  createManifest();
  loadContent();

  function createManifest() {

    var mani = manifest;
    mani.start_url = window.location.href;

    const stringManifest = JSON.stringify(mani);
    const blob = new Blob([stringManifest], { type: 'application/json' });
    const manifestURL = URL.createObjectURL(blob);
    document.querySelector('#manifest').setAttribute('href', manifestURL);

  }

  function loadContent() {
    const search = new URLSearchParams(window.location.search);
    const id = search.get('id');

    var item = data.find((item) => {

      return id === item.id;

    });

    if (!item) {
      item = data.find((item) => {

        return item.id === 'co';

      });
    }

    cardData = item;

    if (item.id == 'jm' || item.id == "fm") {
      //set logo

      logo = mb_logo;
      btnclass = classes.button_mbg;
      switchclass = classes.switch_mbg;
    }

    if (item.id == 'ff') {
      //set logo

      logo = iff_logo;
      btnclass = classes.button_iff;
      switchclass = classes.switch_iff;
    }

  /*   if (cardData.vcard.length > 0) {
      fetch(require(`${cardData.vcard}`)).then((file) => file.text()).then((content) => {
        var data = btoa(content);
        setcardData64('data:text/x-vcard;charset=utf-8;base64,' + data);
      });
    } */

  }

 function downloadCard(){

  if (cardData.vcard.length > 0) {
    fetch(require(`${cardData.vcard}`)).then((file) => file.text()).then((content,file) => {

      const blob = new Blob([content],{type:"text/x-vcard;charset=UTF-8"});

      const url = URL.createObjectURL(blob);
      const element = document.createElement('a');
      element.href = url;
      element.download = cardData.name ?? '';
      element.click();
      element.remove();
      URL.revokeObjectURL(url);

    });
  }

 }

  function showBack() {
    if (cardData.qrcode !== '') {
      setFlipped(flipped = true);
    }
  }

  function showFront() {
    setFlipped(flipped = false);
  }
  return (

    <div className="App">
      <MuiThemeProvider theme={THEME}>
        <header className="App-header">
          <div className={classes.vcenter}>
            <ReactCardFlip isFlipped={flipped}>
              <Card id="front" className={classes.root}>

                <CardHeader className={classes.header} classes={{ action: classes.headeraction }}
                  action={
                    cardData.title.length > 0
                      ? <IconButton aria-label="back" onClick={showBack}>
                        <SwapHorizSharpIcon className={switchclass} />
                      </IconButton>
                      : null
                  } title={<img src={logo} className={classes.logo} alt="logo" />}
                >

                </CardHeader>

                <CardContent className={classes.avcard}>
                  <div className={classes.avatarwrapper}>
                    {cardData.image === ""
                      ? <Avatar aria-label={cardData.name} className={classes.avatar}   >C</Avatar>
                      : <Avatar aria-label={cardData.name} className={classes.avatar} src={require(`${cardData.image}`)} alt='pic' />
                    }
                  </div>

                  <Typography variant="h6" color='rgb(40, 41, 67)'  >
                    {cardData.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{ whiteSpace: 'pre-line' }} >
                    {cardData.title}
                  </Typography>
                </CardContent>

                <CardContent id="fcontent" className={classes.content}>
                  <List >
                    <ListItem className={classes.ListItem} disableGutters={true}>
                      <ListItemIcon>
                        <EmailIcon />
                      </ListItemIcon>
                      <ListItemText secondary={cardData.mail} />
                    </ListItem>
                    {cardData.mobile.length > 0
                      ? <ListItem className={classes.ListItem} disableGutters={true}>
                        <ListItemIcon>
                          <PhoneAndroidIcon />
                        </ListItemIcon>
                        <ListItemText secondary={cardData.mobile} />
                      </ListItem>
                      : null
                    }

                    {cardData.phone.length > 0
                      ? <ListItem className={classes.ListItem} disableGutters={true}>
                        <ListItemIcon>
                          <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText secondary={cardData.phone} />
                      </ListItem>
                      : null}
                    {cardData.web.length > 0
                      ? <ListItem className={classes.ListItem} disableGutters={true}>
                        <ListItemIcon>
                          <LanguageSharpIcon />
                        </ListItemIcon>
                        <ListItemText secondary={cardData.web} />
                      </ListItem>
                      : null}


                    <ListItem className={classes.ListItem} disableGutters={true}>
                      <ListItemIcon>
                        <LocationOnIcon />
                      </ListItemIcon>
                      <ListItemText primary={cardData.company} secondary={cardData.address} style={{ whiteSpace: 'pre-line' }} />
                    </ListItem>
                    {/* {cardData.title.length > 0
                    ? <ListItem className={classes.ListItem} disableGutters={true} style={{ justifyContent: 'center' }}>
                      <ListItemIcon className={classes.socialIcon}>
                        <FontAwesomeIcon icon={faLinkedin} size="lg" />
                      </ListItemIcon>
                      <ListItemIcon className={classes.socialIcon}>
                        <FontAwesomeIcon icon={faXingSquare} size="lg" />
                      </ListItemIcon>
                    </ListItem>
                    : null} */}

                  </List>
                </CardContent>
                <CardActions className={classes.CardActions} >
                  <Button variant='contained' onClick={downloadCard} target="_blank"  className={btnclass} >
                    Speichern</Button >

                </CardActions>
              </Card>

              <Card id="back" className={classes.root} >
                <CardHeader className={classes.header} classes={{ action: classes.headeraction }}
                  action={
                    cardData.title.length > 0
                      ? <IconButton aria-label="back" onClick={showFront}>
                        <SwapHorizSharpIcon className={switchclass} />
                      </IconButton>
                      : null
                  } title={<img src={logo} className={classes.logo} alt="logo" />}
                >

                </CardHeader>
                <CardContent className={classes.avcard}>
                  <div className={classes.avatarwrapper}>
                    {cardData.image === ""
                      ? <Avatar aria-label={cardData.name} className={classes.avatar}   >C</Avatar>
                      : <Avatar aria-label={cardData.name} className={classes.avatar} src={require(`${cardData.image}`)} alt='pic' />
                    }
                  </div>

                  <Typography variant="h6" color='rgb(40, 41, 67)'  >
                    {cardData.name}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" style={{ whiteSpace: 'pre-line' }}>
                    {cardData.title}
                  </Typography>
                </CardContent>

                <CardContent id="bcontent" >

                  <CardMedia
                    className={classes.media}
                    image={cardData.qrcode.length > 0 ? require(`${cardData.qrcode}`) : ''}
                  />
                </CardContent>

                <CardActions className={classes.CardActions} >
                  <Button variant='contained' onClick={downloadCard} className={btnclass}>
                    Speichern</Button >

                </CardActions>
              </Card>
            </ReactCardFlip>
          </div>
        </header>
      </MuiThemeProvider>
    </div>
  );
}

export default App;
